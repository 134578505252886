import logo from './logo.svg';
import './App.css';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p><b>AEC Applications by Nuvolem</b></p>
      </header>
      <body className='App-body'>
        <p>
          We are a startup working to develop applications for AEC productivity.
        </p><p>
          Please check back to hear about applications that let BIM admins manage the firm-wide apps and configurations, improve productivity and centralize content management.
        </p>
      </body>
      <Footer />
    </div>
  );
}

export default App;

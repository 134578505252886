import React from 'react';

const Footer = () => {
  return (
    <footer className="App-footer">
      <div className="container mx-auto text-center">
        <p className="text-gray-800">
          &copy; {new Date().getFullYear()} Nuvolem. All rights reserved. Find us on <a href='https://www.linkedin.com/company/nuvolem'>LinkedIn</a>.
        </p>
      </div>
    </footer>
  );
};

export default Footer;